<template>
  <div>    
    <v-row>
      <v-col md="12" lg="12" sm="12" class="pb-1">
        <s-crud       
          searchInput  pdf @pdf=pdf($event)
          :config="this.config"
          ref="crud"
          :filter="filter"
          title="Cotizaciones"
          @rowSelected="selectRows($event)"
        >
          <template v-slot:filter>
            <v-container>

              <v-row justify="center">  
                  <v-col lg=3 class="pb-0">
                 <s-select label="Usuario" placeholder="Digite Usuario"
                  autofocus autocomplete clearable item-text="NtpFullName" v-model=filter.UsrCreateID item-value="UsrID" :items=itemsUsers></s-select>
              </v-col>  
                    <v-col lg="3" cols=12 class="pb-0">
                  <s-provider  v-model="filter.SupCode" />
                </v-col>             
                <v-col lg="2">
                  <s-date clearable
                    label="Fecha Inicio"
                    v-model="filter.QuoBeginDate"
                  ></s-date>
                </v-col>
                <v-col lg="2">
                  <s-date clearable label="Fecha Fin" v-model="filter.QuoEndDate"></s-date
                ></v-col>
              
              </v-row>
            </v-container>
          </template>
          
        </s-crud>
      </v-col>    
    </v-row>
    <v-row> </v-row>
  </div>
</template>

<script>
import _sQuote from '@/services/Logistics/QuoteService.js'
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import _sPermit from "@/services/Logistics/PermitService";
export default {
  components: { OrderPurchaseEdit },

  data: () => ({
    item: {},
    dialogEdit: false,
    disabledRate: false,
    filter: { TypeOrder: 0, BeginDate: null, EndDate: null },
    config: {
      model: {
        QuoID: "ID",
        SecCreate: "date",
        OrdStatusName: "",
        OrdStatus: "",
      },
      service: _sQuote,
      headers: [
        { text: "ID", value: "QuoID", sortable: false },
        { text: "Documento", value: "QuoDocumentNumber", sortable: false },
       
        { text: "Fecha", value: "SecCreate", sortable: false },
             { text: "Proveedor", value: "SupName", sortable: false },
               { text: "Contacto", value: "QuoContactName", sortable: false },
          { text: "Telefono", value: "QuoContactTelephone", sortable: false },
           { text: "Email", value: "QuoContactEmail", sortable: false },
   
      ],
    },
  }),
  created() {
      _sPermit.users(this.$fun.getUserID()).then((r)=>{
           this.itemsUsers=r.data;
       })
  },

  methods: {
    pdf(item){
      _sQuote.PDF({filter:item},this.$fun.getUserID()).then((r)=>{
 this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Cotización");
      })
    },
    selectRows(items) {
      if (items.length > 0) {
        this.parentID = items[0].OrdID;
        this.item = items[0];
      } else this.parentID = null;
    },
  },
};
</script>
